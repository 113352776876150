<template>
  <section class="custom-background pb-12 overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ヘッダ直下の画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ＰＣのような幅 640 px 以上の画面で表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div  class="hidden sm:block">
          <div class="mx-auto w-8/12 z-30 relative">
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="rounded-3xl cursor-pointer"
                :src="series.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ＰＣのような幅 640 px 以上の画面で表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ スマートフォンのような幅 640 px 未満の画面で表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div  class="visible sm:hidden">
          <div class="mx-auto w-full z-30 relative">
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="cursor-pointer"
                :src="series.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ スマートフォンのような幅 640 px 未満の画面で表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ヘッダ直下の画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- プロジェクトシリーズ紹介テキスト ---+---------+---------+------ -->
        <!--
        <div style="width: 1140px" class="mx-auto w-full z-30 relative mt-10">
          <div class="title custom-title">{{ $t('PROJECT_ABOUT_SERIES_SECTION_TITLE') }}</div>
        </div>
        -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- シリーズ紹介テキスト -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
              v-if="localizedDescription"
              v-html="$sanitize(localizedDescription)"
              class="width-1140-no-padding py-2 px-4 mx-auto mt-8 mb-4 text-lg black-text description-CSS"
        ></div>
        <!-- 外部サイトのURLを埋め込み表示 (wordpressで構築したサイトを表示する想定)-+---------+---------+---------+------ -->
         <IframeWithLoading v-if="series.wordpress_link" :src="series.wordpress_link"></IframeWithLoading>
        <!-- エディション表示の場合と、ＮＦＴ表示の場合がある -------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ show_nftlist==0 エディション一ページ分表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div v-if="series.show_nftlist == 0">
          <div
            v-if="editions && editions.length > 0"
            class="mx-auto width-1140 mt-10"
          >
            <CardListEdition
              @onPageChange="onPageChange"
              :data="editions"
              :numberOfCard="numberOfEditions"
              ></CardListEdition>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ show_nftlist==0 エディション一ページ分表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!-- ↓ show_nftlist≠0 ＮＦＴ一ページ分表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <div v-else>
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
          <!-- 「NFT一覧」セクションタイトル -->
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
          <div style="width: 1140px" class="mx-auto w-full z-30 relative mt-10">
            <div class="title custom-title">
              {{ $t('PROJECT_NFT_LIST_SECTION_TITLE') }} <!-- 「ＮＦＴ一覧」 -->
            </div>
          </div>
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
          <!-- ↓ NFT一覧 ↓ -->
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
          <div
            class="mx-auto width-1140"
          >
            <CardListNFT
              @onPageChange="onPageChange"
              :data="list"
            ></CardListNFT>
          </div>
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
          <!-- ↑ NFT一覧 ↑ -->
          <!--  ---+---------+---------+---------+---------+---------+-----  -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
        <!-- ↑ show_nftlist≠0 ＮＦＴ一ページ分表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+-----  -->
      </div>
      <!-- 外部サイトのURLを埋め込み表示              -->
      <!-- (wordpressで構築したサイトを表示する想定)。-->
      <!-- 企画チーム確認用としてid133のみ表示。      -->
      <!--
      <iframe v-if="series.wordpress_link"
        ref="childIframe"
        style="width:100%;"
        :src="series.wordpress_link"
        scrolling="no">
      </iframe>
      -->
    </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// １ページに表示する画像数。
import { PICTURE_PER_PAGE     } from '@/constants/common.constant';
// エディション一覧表示用。
import CardListEdition    from '@/components/edition/EditionCardList/index.vue';
// NFT一覧表示用。
import CardListNFT              from '@/components/nft/CardList/index.vue';
import { mapActions, mapState } from 'vuex';
import { getCategories        } from '@/services/categories';
import IframeWithLoading        from '@/components/common/IframeWithLoading.vue'
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'page-series',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  components: { CardListEdition, CardListNFT, IframeWithLoading},
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      series: '',
      editions: [],
      numberOfEditions: 0,
    }; // End of  return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    // ],
  }, // End of metaInfo: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapState({
      // store にセットした、言語モード（日本語／英語）。
      locale: (state) => state?.setting.locale,
      // store にセットした、マーケットプレイスのＮＦＴ情報。
      list: (state) => state.marketplace.marketplaceList,
      //total: (state) => state.marketplace.totalItems || 1,
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }), // End of ...mapState({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    localizedDescription() {
      const result = (this.locale === 'en'  ? this.series.description_en
                                            : this.series.description_ja
      );
      return result;
    }, // End of localizedDescription() {...}
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
    //alert(`START: mounted: series.page.vue`);
    // ヘッダのカテゴリ選択コンボボックスと検索テキストボックスを初期化。
    this.clearHeaderCondition();
    // シリーズ情報取得。
    await this.getSeries(this.id);
    if (this.series.show_nftlist == 0) {
      await this.getEditions(1); // 1 はページ。
    } else {
      await this.fetchNewPage(1);
    } // End of if (this.series.show_nftlist == 0) {...} else {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // パンくずリストをヘッダに書くためのコード。現在未使用。
    this.setHierarchy({hierarchy: this.series.hierarchy,
                         name_en: this.series.name_en,
                         name_ja: this.series.name_ja,
    });
    window.addEventListener('message', this.handleMessage);
  }, // End of async mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  // beforeDestroy() {
  //   window.removeEventListener('message', this.handleMessage);
  // },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    ...mapActions({
      // store にある、ヘッダのカテゴリコンボボックスと検索テキストボックスの
      // 値をクリア。
      clearHeaderCondition: 'extractcondition/clearHeaderCondition',
      // store にマーケットプレイスのＮＦＴ情報をセット。
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      setHierarchy: 'hierarchy/setHierarchy',
    }), // End of ...mapActions({...})
    handleMessage(event) {
      const iframe = this.$refs.childIframe;
      iframe.style.height = `${event.data}px`;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ページが切り替わったら実行。下記の二つの場合があり、処理が異なる。
    //   ・show_nftlist=0のエディション＆ＮＦＴ表示
    //   ・show_nftlist=1のＮＦＴのみ表示。
    async onPageChange(page) {
      if (this.series.show_nftlist == 0) {
        await this.getEditions(page);
      } else {
        await this.fetchNewPage(page);
      } // End of if (this.series.show_nftlist == 0) {...} else {...}
    }, // End of async onPageChange(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories から
    // シリーズ情報（オブジェクト）を一つ取得し this.series に取り込む。
    async getSeries(id) {
      const {total: total, items: items} = await getCategories({
        type:  2,
        id  : id,
      });
      if (total==0) {
        // データが取得できなかったらエラーページに遷移。
        await this.$router.push(`/ERROR_PAGE`)
      }
       this.series = items[0];
    }, // End of async getSeries(id) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories から当該シリーズの
    // エディション情報（オブジェクト）を一ページ分取得し this.editions に
    // 取り込む。
    async getEditions(page) {
      const objectAboutEdition = {
        type: 3, // テーブル categories のエディションレコードを指定。
        limit: PICTURE_PER_PAGE, // 取得エディション数。現在 12。
        offset: PICTURE_PER_PAGE * (page - 1), // １ページなら0、２ページなら12。
        hierarchy: this.series.hierarchy + '\\' + this.series.name_en,
        is_deleted: 0, // 論理削除されていないエディションのみ取得。
      }; // End of const objectAboutEdition = {...}
      // alert(`objectAboutEdition=${JSON.stringify(objectAboutEdition)}: getEditions(page=${page}): series.page.vue`);
      // +---------+---------+---------+---------+---------+---------+---------E
      const {total: total, items: items} =
          await getCategories(objectAboutEdition);
      this.numberOfEditions = total;
      this.editions = items;
    }, // End of async getSeries(objectAboutEdition) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル projects から
    // レコード情報（オブジェクト）を一ページ分 12 個取得。store に反映。
    // 「階層」hierarchy がこの画面のシリーズの画像情報のみ取得。
    async fetchNewPage(page) {
      // alert(`*** START: fetchNewPage: series.page.vue ***`);
      this.loading = true;
      const offset = PICTURE_PER_PAGE * (page - 1);
      await this.fetchMarketplace({
        limit    : PICTURE_PER_PAGE,
        offset   : offset,
        //hierarchy: this.series.hierarchy + '\\' + this.series.name_en,
        hierarchy: JSON.stringify([
          this.series.hierarchy + '\\' + this.series.name_en,
          this.series.hierarchy + '\\' + this.series.name_en + '\\%',
        ]),
      }); // End of await this.fetchMarketplace({...})
      this.loading = false;
      // alert(`*** END  : fetchNewPage: series.page.vue ***`);
    }, // End of async fetchNewPage(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // this.series.link の URL を開く。一番上の画面をクリックしたときの処理。
    goToLinkPlace() {
      // window.open(this.series.link, '_blank');
    },
  }, // End of methods: {...}
}; // End of methods: {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>
.custom-background {
    background-color: white; /* ここで好きな色を指定 */
} 
::v-deep .category-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}


.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}
.width-1140-no-padding {
  @screen xl {
    width: 1140px;
  }
}

.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
  /* タイトル用の基本スタイル */
  .title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  /* color: #FFFFFF; テキストカラー 白 */
  color: #000000; /* テキストカラー 黒 */

  margin-bottom: 20px; /* 下余白 */
  padding: 10px 10px; /* 上下パディング */
}
.black-text {
  color: black;
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}

::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
::v-deep .ant-select {
  color: #fff;
  @apply font-semibold;
  &-arrow {
    color: white;
  }
  &-selection {
    background-color: transparent;
    border: 1px solid white;
    @apply rounded-xl;
    @apply w-32 h-6;
    @apply text-xs;
    &__rendered {
      @apply mx-0;
      @apply w-full h-full;
      .ant-select-selection-selected-value {
        @apply w-full h-full text-center relative -top-1;
      }
    }
  }
}
/*以下カテゴリ紹介文内のCSSに適用 */
::v-deep .description-CSS p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS a > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}
@media (max-width: 768px) {
  ::v-deep .description-CSS p {
    max-width: 100%;
  }
}
</style>
