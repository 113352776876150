<template>
  <div class="iframe-container">
    <a-spin :spinning="loading">
      <iframe
        v-show="!loading"
        :src="src"
        ref="childIframe"
        @load="onIframeLoad"
        class="iframe-content"
        scrolling="no"
      ></iframe>
    </a-spin>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    onIframeLoad() {
      this.loading = false;
    },
    handleMessage(event) {
      const iframe = this.$refs.childIframe;
      iframe.style.height = `${event.data}px`;
    },
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
}
</style>